.work1 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000028;
    width: 100vw;
}

.work1 h1 {
    font-size: 3.8vw;
    font-weight: 700;
    margin-top: 4%;
    border-bottom: 4px solid red;
    text-align: center;
    width: 20%;
}

.work2 {
    width: 100vw;
    /* margin-bottom: 12%; */
    background-color: #00000028;
    height: 100%;
    padding-bottom: 60%;
}

.work2gallery {
    display: grid;
    grid-template-rows: auto auto auto auto auto auto auto auto;
    grid-template-columns: 25vw 25vw 25vw;
    padding: 5% 8%;
    /* align-content: center; */
    row-gap: 5%;
    column-gap: 5%;
}

.workmodel {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 10001;
}

.workmodel.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.workmodel img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.workmodel.open svg {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 1.2rem;
    height: 1.2rem;
    color: rgb(255, 255, 255);
    background-color: black;
    cursor: pointer;
}

.vehiclesP {
    grid-row: 1/2;
    grid-column: 1/2;
    /* width: 90%; */
    /* height: 90%; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
}

.vehiclesP img,
.smm img,
.valentineP img,
.thug-lifeP img,
.partyP img,
.fitnessP img,
.chaiPoint1P img,
.chaiPoint2P img,
.shoesP img,
.spiderP img,
.chaipoint3P img,
.laptop img,
.saturday img,
.floral img,
.grid img,
.snapinsta1 img,
.snapinsta2 img,
.snapinsta3 img,
.snapinsta4 img,
.wedding img {
    /* width: 70%; */
    height: 95% !important;
}

.laptop {
    grid-row: 4/5;
    grid-column: 2/3;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.saturday {
    grid-row: 4/5;
    grid-column: 3/4;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.floral {
    grid-row: 5/6;
    grid-column: 1/2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.grid {
    grid-row: 5/6;
    grid-column: 2/3;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.snapinsta2 {
    grid-row: 5/6;
    grid-column: 3/4;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.snapinsta3 {
    grid-row: 6/7;
    grid-column: 1/2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.snapinsta4 {
    grid-row: 6/7;
    grid-column: 2/3;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.wedding {
    grid-row: 6/7;
    grid-column: 3/4;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.snapinsta1 {
    grid-row: 7/8;
    grid-column: 2/3;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.smm {
    /* width: 30%; */
    grid-row: 7/8;
    grid-column: 1/2;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    overflow: hidden;
}

.valentineP {
    grid-row: 1/2;
    grid-column: 3/4;
    /* width: 30%; */
    /* height: 64vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.thug-lifeP {
    grid-row: 2/3;
    grid-column: 1/2;
    /* width: 30%;
    height: 70vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.partyP {
    grid-row: 2/3;
    grid-column: 2/3;
    /* width: 30%;
    height: 70vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.fitnessP {
    grid-row: 2/3;
    grid-column: 3/4;
    /* width: 30%;
    height: 70vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.chaiPoint1P {
    grid-row: 3/4;
    grid-column: 1/2;
    /* width: 30%;
    height: 70vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.chaiPoint2P {
    grid-row: 3/4;
    grid-column: 2/3;
    /* width: 30%;
    height: 70vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.shoesP {
    grid-row: 3/4;
    grid-column: 3/4;
    /* width: 30%;
    height: 70vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.spiderP {
    grid-row: 4/5;
    grid-column: 1/2;
    /* width: 30%; */
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.chaipoint3P {
    grid-row: 1/2;
    grid-column: 2/3;
    /* width: 30%; */
    /* height: 64vh !important; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.vehiclesP:hover,
.chaipoint3P:hover,
.chaiPoint1P:hover,
.chaiPoint2P:hover,
.fitnessP:hover,
.partyP:hover,
.shoesP:hover,
.smm:hover,
.spiderP:hover,
.thug-lifeP:hover,
.valentineP:hover,
.laptop:hover,
.saturday:hover,
.floral:hover,
.grid:hover,
.snapinsta1:hover,
.snapinsta2:hover,
.snapinsta3:hover,
.snapinsta4:hover,
.wedding:hover {
    cursor: pointer;
    filter: brightness(0.5);
    transition: filter 0.6s ease-in-out;
}

.swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */
    height: calc((100% - 30px) / 2) !important;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.work3 {
    height: 85vh;
    padding: 4% 7%;
    /* background-color: #00000023; */
    margin-bottom: 4%;
}

.work3 img {
    width: 38%;
}

.work3 h2 {
    /* margin-top: 10%; */
    font-weight: 700;
    font-size: xx-large;
    font-family: 'Kalam', cursive;
    /* margin-bottom: 2%; */
}

.work3 h2>span {
    color: red;
    font-size: xxx-large;
}

.work4 {
    background-color: #000;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    /* flex-direction: column; */
    row-gap: 2%;
    width: 100vw;
    align-items: center;
    justify-items: center;
    padding: 0 10%;
}

.vid1 {
    width: auto;
    height: 80%;
    grid-column: 1/2;
    grid-row: 1/3;
}

.vid2 {
    width: 30rem;
    height: auto;
    grid-column: 2/3;
    grid-row: 1/2;
}

.vid3 {
    width: 30rem;
    height: auto;
    grid-column: 2/3;
    grid-row: 2/3;
}


/* @media screen and (max-width:1024px) {
    .vid1 {
        height: 40vh;
    }

    .vid2 {
        height: 40vh;
    }
} */

@media screen and (max-width:730px) {
    .work1 h1 {
        font-size: 8vw;
        width: 40%;
    }

    .work2 {
        height: 100%;
        padding-bottom: 100%;
    }

    .work2gallery {
        grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
        grid-template-columns: 40vw 40vw;
    }

    .smm {
        grid-row: 10/11;
        grid-column: 1/2;
        height: 60vh;
    }

    .laptop {
        grid-row: 6/7;
        grid-column: 1/2;
    }

    .saturday {
        grid-row: 6/7;
        grid-column: 2/3;
    }

    .floral {
        grid-row: 7/8;
        grid-column: 1/2;
    }

    .grid {
        grid-row: 7/8;
        grid-column: 2/3;
    }

    .snapinsta1 {
        grid-row: 10/11;
        grid-column: 2/3;

    }

    .snapinsta2 {
        grid-row: 8/9;
        grid-column: 1/2;
    }

    .snapinsta3 {
        grid-row: 8/9;
        grid-column: 2/3;
    }

    .snapinsta4 {
        grid-row: 9/10;
        grid-column: 1/2;
    }

    .wedding {
        grid-row: 9/10;
        grid-column: 2/3;
    }

    .valentineP {
        grid-row: 2/3;
        grid-column: 1/2;
    }

    .thug-lifeP {
        grid-row: 2/3;
        grid-column: 2/3;
    }

    .partyP {
        grid-row: 3/4;
        grid-column: 1/2;
    }

    .fitnessP {
        grid-row: 3/4;
        grid-column: 2/3;
    }

    .chaiPoint1P {
        grid-row: 4/5;
        grid-column: 1/2;
    }

    .chaiPoint2P {
        grid-row: 4/5;
        grid-column: 2/3;
    }

    .shoesP {
        grid-row: 5/6;
        grid-column: 1/2;
    }

    .spiderP {
        grid-row: 5/6;
        grid-column: 2/3;
    }

    .work3 {
        height: 100vh;
        padding: 16% 7%;
        margin-bottom: 4%;
    }

    .work3 img {
        width: 60%;
    }

    .vid1 {
        grid-row: 1/2;
        grid-column: 1/2;
        height: unset;
        width: 80vw;
    }

    .vid2 {
        grid-row: 2/3;
        grid-column: 1/2;
        height: unset;
        width: 80vw;
    }

    .vid3 {
        grid-row: 3/4;
        grid-column: 1/2;
        /* width: 80vw; */
        height: unset;
        width: 80vw;
    }

    .work4 {
        background-color: #000;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        /* row-gap: 10px !important; */
        width: 100vw;
        align-items: center;
        justify-content: center;
        padding: 20% 0;
    }

}

@media screen and (max-width:480px) {


    .work3 {
        padding: 30% 7% !important;
    }

    .work2 {
        height: 100%;
        padding-bottom: 230vh;
    }

    .work2gallery {
        grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto;
        grid-template-columns: 85vw;
        row-gap: 1%;
    }

    .chaipoint3P {
        grid-row: 2/3;
        grid-column: 1/2;
    }

    .smm {
        grid-row: 19/20;
        grid-column: 1/2;
        height: 70vh !important;
    }

    .laptop {
        grid-row: 11/12;
        grid-column: 1/2;
    }

    .saturday {
        grid-row: 12/13;
        grid-column: 1/2;
    }

    .floral {
        grid-row: 13/14;
        grid-column: 1/2;
    }

    .grid {
        grid-row: 14/15;
        grid-column: 1/2;
    }

    .snapinsta1 {
        grid-row: 20/21;
        grid-column: 1/2;

    }

    .snapinsta2 {
        grid-row: 15/16;
        grid-column: 1/2;
    }

    .snapinsta3 {
        grid-row: 16/17;
        grid-column: 1/2;
    }

    .snapinsta4 {
        grid-row: 17/18;
        grid-column: 1/2;
    }

    .wedding {
        grid-row: 18/19;
        grid-column: 1/2;
    }

    .valentineP {
        grid-row: 3/4;
        grid-column: 1/2;
    }

    .thug-lifeP {
        grid-row: 4/5;
        grid-column: 1/2;
    }

    .partyP {
        grid-row: 5/6;
        grid-column: 1/2;
    }

    .fitnessP {
        grid-row: 6/7;
        grid-column: 1/2;
    }

    .chaiPoint1P {
        grid-row: 7/8;
        grid-column: 1/2;
    }

    .chaiPoint2P {
        grid-row: 8/9;
        grid-column: 1/2;
    }

    .shoesP {
        grid-row: 9/10;
        grid-column: 1/2;
    }

    .spiderP {
        grid-row: 10/11;
        grid-column: 1/2;
    }

    .work3 {
        padding-top: 10%;
        padding-bottom: 30%;
        padding: unset 7%;
    }

    .work3 img {
        width: 90%;
    }

    .work3 h2 {
        font-weight: 700;
        font-size: x-large;
        /* margin-bottom: 2%; */
    }
}

@media screen and (max-width:400px) {

    .work2 {
        height: 100%;
        padding-bottom: 200vh;
    }

}