@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@500&family=Kalam&family=Noto+Serif:wght@700&display=swap');

.slide1 {
    background: url("../images/home1-bg2.jpg");
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 100;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -7%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8% 8%;
}

.slide1-1 {
    width: 35%;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slide1-1 h5 {
    color: rgb(220, 0, 0);
    font-weight: 700;
    text-align: left;
}

.slide1-1 h1 {
    /* font-family: 'Lilita One', cursive; */
    /* font-family: 'Patua One', cursive; */
    font-family: 'Gabarito', cursive;
    font-size: 2.8vw;
    text-align: left;
    color: rgb(0, 0, 83);
}

.slide1-1-btn {
    display: flex;
    column-gap: 3%;
    padding-top: 5%;
}

.start-project-btn,
.about-us-btn {
    /* padding: 1%; */
    width: 12vw;
    background: transparent;
    /* color: rgb(0, 0, 83);
    border-color: rgb(0, 0, 83); */
}

.slide2 {
    background: url("../images/home1-bg.jpg");
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 100;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -7%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8% 4%;
}

.slide2-1 {
    width: 40%;
    margin-top: 7%;
    margin-left: 4%;
}

.slide2-1 h1 {
    font-family: 'Gabarito', cursive;
    font-size: 3vw;
    color: rgb(0, 0, 83);
    margin-bottom: 5%;
    text-align: left;
}

.slide2-1 li,
.slide2-1 p {
    font-size: 1.2vw;
    font-weight: 500;
    margin-top: 1%;
    text-align: left
}

.slide3 {
    background: url("../images/home1-bgg3.png");
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 100;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: -7%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8% 5%;
}

.slide3-1 {
    width: 40%;
    margin-top: 7%;
    margin-left: 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slide3-1 h1 {
    font-family: 'Gabarito', cursive;
    font-size: 3vw;
    color: rgb(0, 0, 83);
    margin-bottom: 5%;
}

a {
    color: unset;
    /* text-decoration: none; */
}

a:hover {
    text-decoration: none;
    color: unset;
}

.contact-us {
    /* padding: 1%; */
    width: 12vw;
    background: transparent;
    /* color: rgb(0, 0, 83);
    border-color: rgb(0, 0, 83); */
    margin-bottom: 5%;
}

.social-media {
    display: flex;
    column-gap: 10%;
}

.insta,
.facebook,
.linkedin,
.twitter {
    font-size: 1.7vw;
    transition: font-size 0.5s;
}

.insta:hover,
.facebook:hover,
.linkedin:hover,
.twitter:hover {
    font-size: 1.9vw;
    cursor: pointer;
}

button:hover {
    cursor: pointer;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide div {
    object-fit: cover;
}

.home2-1 {
    display: grid;
    grid-template-columns: auto auto;
    grid-row: 30% 70%;
    /* height: 70vh; */
}

.home2-11,
.home3-1,
.home4-1 {
    background-color: rgb(232, 232, 232);
    grid-row: 1/2;
    grid-column: 1/3;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-top: 7%; */
    height: 35vh;
    padding-top: 10%;
    padding-bottom: 2%;
}

.home3-1 {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    /* margin-bottom: unset; */
}

.home2-11 h1,
.home3-1 h1,
.home4-1 h1 {
    font-size: 8vw;
    font-weight: 800;
}

.home2-12 {
    background-color: rgb(232, 232, 232);
    display: flex;
    flex-direction: column;
    padding: 10% 18%;
    height: 70vh;
    border-right: 2px solid rgb(232, 232, 232);
}

.home2-13 {
    background-color: rgb(232, 232, 232);
    display: flex;
    flex-direction: column;
    padding: 10% 18%;
    /* background-color: rgb(242, 242, 242); */
}

.home2-12 h3,
.home2-13 h3 {
    font-size: 2.5vw;
    font-weight: 700;
}

.green-line {
    width: 30%;
    height: 7%;
    border-top: 3px solid red;
}

.qmark {
    color: rgb(71, 71, 211);
}

.home2-12 h5,
.home2-13 h5 {
    font-family: 'Kalam', cursive;
    font-size: 1.5rem;
    margin-bottom: 5%;
}

.home2-12 p,
.home2-13 p {
    color: rgb(75, 75, 75);
    font-size: medium;
    margin-bottom: 10%;
}

.home2-12 h4,
.home2-13 h4 {
    font-weight: 650;
    margin-left: 0;
    transition: margin-left 0.5s;

}

.home2-12 h4:hover,
.home2-13 h4:hover {
    cursor: pointer;
    margin-left: 2%
}

.home2-2 {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    grid-template-rows: 46vh 46vh 46vh;
    height: 100%;
    /* justify-items: center; */
}

.social-media-mkt {
    grid-row: 1/2;
    grid-column: 1/2;
    position: absolute;
    height: 46vh;
    width: 33.33%;
    overflow: hidden;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.4s ease-in-out;
    background: black;
    visibility: hidden;
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-family: verdana;
    text-align: center;
    /* display: none; */
}

.social-media-mkt:hover .overlay,
.performance-mkt:hover .overlay,
.web-analytics:hover .overlay,
.content-writing:hover .overlay,
.seo:hover .overlay,
.web-design:hover .overlay,
.influencer:hover .overlay,
.ads:hover .overlay,
.youtube:hover .overlay {
    opacity: 0.7;
    visibility: visible;
    cursor: pointer;
}

.performance-mkt {
    grid-row: 1/2;
    grid-column: 2/3;
    position: absolute;
    height: 46vh;
    width: 33.33%;
    margin-left: 33.33%;
    overflow: hidden;
}

.web-analytics {
    grid-row: 1/2;
    grid-column: 3/4;
    position: absolute;
    height: 46vh;
    width: 33.33%;
    margin-left: 66.66%;
    overflow: hidden;
}

.content-writing {
    grid-row: 2/3;
    grid-column: 1/2;
    position: absolute;
    height: 46vh;
    width: 33.33%;
    margin-top: 46vh;
    overflow: hidden;
}

.seo {
    grid-row: 2/3;
    grid-column: 2/3;
    position: absolute;
    height: 46vh;
    width: 33.33%;
    margin-top: 46vh;
    margin-left: 33.33%;
    overflow: hidden;
}

.seo img,
.social-media-mkt img,
.performance-mkt img,
.web-design img,
.web-analytics img,
.content-writing img,
.influencer img,
.ads img,
.youtube img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: all 0.6s linear;
}

.seo:hover img,
.social-media-mkt:hover img,
.performance-mkt:hover img,
.web-design:hover img,
.web-analytics:hover img,
.content-writing:hover img,
.influencer:hover img,
.ads:hover img,
.youtube:hover img {
    /* width: 103%; */
    transform: scale(1.1);
}

.web-design {
    grid-row: 2/3;
    grid-column: 3/4;
    position: absolute;
    height: 46vh;
    width: 33.33%;
    margin-top: 46vh;
    margin-left: 66.66%;
    overflow: hidden;
}

.influencer {
    grid-row: 3/4;
    grid-column: 1/2;
    position: absolute;
    height: 46vh;
    width: 33.33%;
    margin-top: -46vh;
    /* margin-left: 0; */
    overflow: hidden;
}

.ads {
    grid-row: 3/4;
    grid-column: 3/4;
    position: absolute;
    height: 46vh;
    width: 33.33%;
    margin-top: -46vh;
    margin-left: 66.66%;
    overflow: hidden;
}

.youtube {
    grid-row: 3/4;
    grid-column: 2/3;
    position: absolute;
    margin-top: -46vh;
    height: 46vh;
    width: 33.33%;
    margin-left: 33.33%;
    overflow: hidden;
}


.home3-2 {
    background: url("../images/choose-bg.jpg");
    background-size: cover;
    position: relative;
    z-index: 2;
    background-repeat: no-repeat;
    color: white;
    height: 65vh;
    width: 100vw !important;
}

.black-bg {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
}

.black-bg:before {
    position: absolute;
    content: '';
    background-color: black !important;
    width: 100vw;
    height: 65vh;
    z-index: -1;
    opacity: 0.8;
}

.home3-21 {
    display: flex;
    padding: 7% 10%;
    column-gap: 10%;
    align-items: center;
    justify-content: center;
    height: 65vh;
    width: 100vw;
}


.choose-content1 h1 {
    width: 80%;
    font-size: 3rem;
    font-weight: 700;
}

.choose-content1 p {
    font-size: medium;
    width: 80%;
}

.strategy,
.audience,
.expertise {
    display: flex;
    column-gap: 3rem;
    margin-bottom: 4%;
}

.outer-circle {
    border: 2px solid black;
    width: 15vh;
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: width 1s;
    background-color: rgb(210, 203, 255);
    color: black;
}

.outer-circle:hover {
    cursor: pointer;
    width: 16vh;
    background-color: rgb(89, 63, 255);
}

.strategy h3,
.audience h3,
.expertise h3 {
    font-size: 1.2rem;
    font-weight: 700;
}

.strategy p,
.audience p,
.expertise p {
    font-size: medium;
    font-weight: 500;
}

.strategy-content,
.audience-content,
.expertise-content {
    width: 60%;
}

.audience {
    margin-left: 10%;
}

.home3-22 {
    width: 100vw;
    padding: 14% 10%;
    display: flex;
    justify-content: space-between;
}

.work-recieved,
.cup-of-coffee,
.project-complete,
.happy-clients {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5%;
}

.work-recieved,
.cup-of-coffee,
.project-complete {
    border-right: 2px solid white;
}

.home3-23 {
    padding: 7%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home3-23 p {
    font-family: 'Kalam', cursive;
    font-size: 2rem;
    margin-bottom: 5%;
}

.home3-23 h1 {
    font-size: 4rem;
    margin-bottom: 8%;
}

.home3-23 h4 {
    font-weight: 650;
    margin-left: 0;
    transition: margin-left 0.5s;

}

.home3-23 h4:hover {
    cursor: pointer;
    margin-left: 1%
}

.home1,
.home2,
.home3,
.home4,
.home5 {
    width: 100vw;
}

.home4-2 {
    margin-top: -10%;
    display: flex;
    justify-content: center;
}

.home4-21 {
    width: 70%;
}


.gallery {
    display: grid;
    grid-template-columns: 50%, 50%;
    grid-template-rows: 70vh 70vh 70vh;
    column-gap: 2%;
}

.vehicles,
.valentine,
.thug-life,
.party,
.fitness,
.chaiPoint1,
.chaiPoint2,
.shoes {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}

.model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000 !important;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.model img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.model.open svg {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 1.2rem;
    height: 1.2rem;
    color: rgb(255, 255, 255);
    background-color: black;
    cursor: pointer;
}

.home4-1 {
    background-color: rgb(0, 0, 0);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding-top: 7%; */
    height: 70vh;
    padding-top: 1%;
    padding-bottom: 7%;
    /* margin-top: -5%; */
}

.home4-1 h4 {
    color: rgb(71, 71, 211);
    font-size: 2.2rem;
}

.vehicles {
    grid-row: 1/2;
    grid-column: 1/2;
    position: absolute;
    width: 33%;
    overflow: hidden;
    height: 70vh;
}

.valentine {
    grid-row: 1/2;
    grid-column: 2/3;
    position: absolute;
    height: 70vh;
    width: 33%;
    margin-left: 33.33%;
    overflow: hidden;
}

.thug-life {
    grid-row: 2/3;
    grid-column: 1/2;
    position: absolute;
    height: 70vh;
    width: 33%;
    /* margin-left: 33.33%; */
    margin-top: 70vh;
    overflow: hidden;
}

.party {
    grid-row: 2/3;
    grid-column: 2/3;
    position: absolute;
    height: 70vh;
    width: 33%;
    margin-left: 33.33%;
    margin-top: 70vh;
    overflow: hidden;
}

.fitness {
    grid-row: 3/4;
    grid-column: 1/2;
    position: absolute;
    height: 70vh;
    width: 33%;
    /* margin-left: 33.33%; */
    margin-top: 140vh;
    overflow: hidden;
}

.chaiPoint1 {
    grid-row: 3/4;
    grid-column: 2/3;
    position: absolute;
    height: 70vh;
    width: 33%;
    margin-left: 33.33%;
    margin-top: 140vh;
    overflow: hidden;
}

.vehicles:hover .work-overlay,
.valentine:hover .work-overlay,
.thug-life:hover .work-overlay,
.party:hover .work-overlay,
.fitness:hover .work-overlay,
.chaiPoint1:hover .work-overlay {
    opacity: 0.7;
    visibility: visible;
    cursor: pointer;
}

.vehicles img,
.valentine img,
.thug-life img,
.party img,
.fitness img,
.chaiPoint1 img {
    width: 98%;
    height: 98%;
    overflow: hidden;
    /* transition: width 1s ease-in; */
    transition: all 0.6s linear;
}

.valentine:hover img,
.vehicles:hover img,
.thug-life:hover img,
.party:hover img,
.fitness:hover img,
.chaiPoint1:hover img {
    /* width: 103%; */
    transform: scale(1.1);
}

.work-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* transition: opacity 0.4s ease-in-out; */
    background: black;
    visibility: hidden;
}

.work-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-family: verdana;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.home4-3 {
    padding-bottom: 5%;
}

.home4-3 h3 {
    /* float: right; */
    margin-left: 70%;
    font-size: larger;
}

.home4-3 h2 {
    margin-top: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 3rem;
    column-gap: 1%;
    transition: margin-left 0.5s ease-in;
}

.home4-3 h2:hover {
    margin-left: 1%;
    cursor: pointer;
}

.home5 {
    padding: 6% 0;
    background-color: rgb(105, 91, 195);
    width: 100vw;
}

.home5-1,
.home5-2,
.home5-3,
.home5-4,
.home5-5 {
    /* width: 70%; */
    background-color: rgb(105, 91, 195);
    padding: 0 12%;
    margin: auto;
}

.home5-1 h4,
.home5-2 h4,
.home5-3 h4,
.home5-4 h4,
.home5-5 h4 {
    margin-bottom: 4%;
    line-height: 2rem;
}

.home5-line {
    border-bottom: 3px solid black;
    width: 10vw;
    height: 2px;
    margin-bottom: 1.5%;
}

.home5-1 h5,
.home5-2 h5,
.home5-3 h5,
.home5-4 h5,
.home5-5 h5 {
    font-family: 'Kalam', cursive;
    font-size: 1.35rem;
    text-align: left;

}

@media screen and (max-width:1024px) {

    .start-project-btn,
    .about-us-btn,
    .contact-us {
        height: 6vh !important;
        width: 19vw;
    }

}

@media screen and (max-width:730px) {

    .slide1,
    .slide2,
    .slide3 {
        background: url("../images/home-img-phone.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left;
        justify-content: center;
        height: 80vh;
    }

    .slide1-1,
    .slide2-1,
    .slide3-1 {
        width: 80vw;
    }

    .slide1-1 h5 {
        font-size: xx-large;
    }

    .slide1-1 h1,
    .slide2-1 h1,
    .slide3-1 h1 {
        font-size: xx-large !important;
        margin-top: 3%;
        text-align: center;
    }

    .slide1-1-btn {
        padding-top: 3%;
    }

    .start-project-btn,
    .about-us-btn,
    .contact-us {
        /* padding: 1%; */
        height: 7vh !important;
        width: 25vw;
        font-size: medium !important;
    }

    .slide2-1 li,
    .slide2-1 p {
        font-size: large;
    }

    .social-media {
        display: flex;
        column-gap: 6%;
    }

    .insta,
    .facebook,
    .linkedin,
    .twitter {
        font-size: 5vw;
        transition: font-size 0.5s;
    }

    .insta:hover,
    .facebook:hover,
    .linkedin:hover,
    .twitter:hover {
        font-size: 4.9vw;
    }

    .home2-1 {
        grid-row: 30% 70% !important;
        height: 100% !important;
    }

    .home2-11 {
        height: 100% !important;
        padding-top: 5%;
    }

    .home2-12 {
        padding: none !important;
        height: unset !important;
    }

    .home2-13 {
        padding: none !important;
    }

    .home2-12 h3,
    .home2-13 h3 {
        font-size: 2.7vw;
    }

    .home2-12 h5 {
        margin-top: 10%;
    }

    .home2-12 h5,
    .home2-13 h5 {
        font-size: 1.3rem;
    }

    .home2-12 h4,
    .home2-13 h4 {
        font-size: larger;
    }

    .home2-2 {
        display: grid;
        grid-template-columns: 50% 50% !important;
        grid-template-rows: 46vh 46vh 46vh 46vh !important;
        /* justify-items: center; */
    }

    .social-media-mkt {
        grid-row: 1/2;
        grid-column: 1/2;
        width: 50%;
    }

    .performance-mkt {
        grid-row: 1/2;
        grid-column: 2/3;
        width: 50%;
        margin-left: 50%;
    }

    .web-analytics {
        grid-row: 2/3 !important;
        grid-column: 1/2 !important;
        width: 50%;
        margin-left: 0 !important;
        margin-top: 46vh;
    }

    .content-writing {
        grid-row: 2/3;
        grid-column: 2/3 !important;
        width: 50%;
        margin-top: 46vh;
        margin-left: 50%;
    }

    .seo {
        grid-row: 3/4;
        grid-column: 1/2;
        width: 50%;
        margin-top: 92vh;
        margin-left: 0 !important;
    }

    .web-design {
        grid-row: 3/4;
        grid-column: 2/3;
        width: 50%;
        margin-top: 92vh;
        margin-left: 50%;
    }

    .influencer {
        grid-row: 4/5;
        grid-column: 1/2;
        width: 50%;
        /* margin-top: -46vh; */
        margin-left: 0;
    }

    .ads {
        visibility: hidden;
    }

    .youtube {
        grid-row: 4/5;
        grid-column: 2/3;
        width: 50%;
        /* margin-top: 138vh; */
        margin-left: 50%;
    }

    .home3-21 {
        padding: 0 5% !important;
        column-gap: 7%;
    }

    .choose-content1 h1 {
        font-size: 2rem !important;
    }

    .choose-content1 p {
        font-size: medium;
        width: 80%;
    }

    .outer-circle h1 {
        font-size: 1.7rem;
    }

    .strategy,
    .audience,
    .expertise {
        column-gap: 1rem;
    }

    .outer-circle {
        width: 10vh;
        height: 10vh;
    }

    .strategy h3,
    .audience h3,
    .expertise h3 {
        font-size: 1rem;
    }

    .strategy p,
    .audience p,
    .expertise p {
        font-size: small;
        font-weight: unset;
    }

    .audience {
        margin-left: 0;
    }

    .home3-23 {
        padding: 10% 7%;
    }

    .home3-23 p {
        font-size: 2.5rem;
    }

    .home3-23 h1 {
        font-size: 3rem;
        margin-bottom: 8%;
    }

    .home4-1 {
        justify-content: unset;
        padding-top: 7%;
        height: 50vh;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 0;
    }

    .home4-2 {
        margin-top: -10%;
        display: flex;
        align-items: center;
    }

    .home4-21 {
        width: 90%;
    }

    .gallery {
        display: grid;
        grid-template-columns: 50%, 50%;
        grid-template-rows: 60vh 60vh 60vh;
        column-gap: 3%;
    }

    .vehicles {
        position: absolute;
        width: 45%;
        overflow: hidden;
        height: 60vh;
    }

    .valentine {
        height: 60vh;
        width: 45%;
        margin-left: 45%;
    }

    .thug-life {
        height: 60vh;
        width: 45%;
        margin-top: 60vh;
    }

    .party {
        height: 60vh;
        width: 45%;
        margin-left: 45%;
        margin-top: 60vh;
    }

    .fitness {
        height: 60vh;
        width: 45%;
        margin-top: 120vh;
    }

    .chaiPoint1 {
        height: 60vh;
        width: 45%;
        margin-left: 45%;
        margin-top: 120vh;
    }

    .check-portfolio {
        font-size: xx-large !important;
        padding: 0 25%;
    }

    .home5-1 h4,
    .home5-2 h4,
    .home5-3 h4,
    .home5-4 h4,
    .home5-5 h4 {
        font-size: 1.1rem;
    }
}

@media screen and (max-width:556px) {

    .slide1-1,
    .slide2-1,
    .slide3-1 {
        width: 100vw;
    }

    .slide1-1 h5 {
        text-align: center;
        /* width: 100vw; */
    }

    .start-project-btn,
    .about-us-btn,
    .contact-us {
        width: 30vw;
    }

    .home2-1 {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-columns: auto !important;
        grid-row: unset !important;
    }

    .home2-11 {
        grid-row: 1/2;
        grid-column: 1/2;
        padding-bottom: unset !important;
        border-bottom: 2px solid rgba(0, 0, 0, 0.177);
    }

    .home2-12 {
        height: unset !important;
    }

    .home2-13 {
        margin-top: -10% !important;
    }

    .home2-12 h3,
    .home2-13 h3 {
        font-size: 7vw;
    }

    .home2-12 h5,
    .home2-13 h5 {
        margin-top: 4% !important;
        font-size: x-large;
    }

    .home2-12 p,
    .home2-13 p {
        font-size: medium;
    }

    .home2-2 {
        display: grid;
        grid-template-columns: 100% !important;
        grid-template-rows: 50vh 50vh 50vh 50vh 50vh 50vh 50vh 50vh 50vh !important;
        /* justify-items: center; */
    }

    .social-media-mkt {
        grid-row: 1/2;
        grid-column: 1/2;
        width: 100%;
        height: 50vh;
    }

    .performance-mkt {
        grid-row: 2/3;
        grid-column: 1/2;
        width: 100%;
        margin-left: 0;
        margin-top: 50vh;
        height: 50vh;
    }

    .web-analytics {
        grid-row: 3/4;
        grid-column: 1/2;
        width: 100%;
        margin-left: 0;
        margin-top: 100vh;
        height: 50vh;
    }

    .content-writing {
        grid-row: 4/5;
        grid-column: 1/2;
        width: 100%;
        margin-top: 150vh;
        margin-left: 0;
        height: 50vh;
    }

    .seo {
        grid-row: 5/6;
        grid-column: 1/2;
        width: 100%;
        margin-top: 200vh;
        margin-left: 0;
        height: 50vh;
    }

    .web-design {
        grid-row: 6/7;
        grid-column: 1/2;
        width: 100%;
        margin-top: 250vh;
        margin-left: 0;
        height: 50vh;
    }

    .influencer {
        grid-row: 7/8;
        grid-column: 1/2;
        width: 100%;
        margin-top: -150vh;
        margin-left: 0;
        height: 50vh;
    }

    .ads {
        grid-row: 8/9;
        grid-column: 1/2;
        width: 100%;
        margin-top: -50vh;
        margin-left: 0;
        height: 50vh;
        visibility: visible;
    }

    .youtube {
        grid-row: 9/10;
        grid-column: 1/2;
        width: 100%;
        margin-top: -100vh;
        margin-left: 0;
        height: 50vh;
    }

    .home3-1 {
        margin: -10% 0;
    }

    .home3-1 h1 {
        font-size: 7.5vw !important;
        border-bottom: 2px solid rgba(0, 0, 0, 0.418) !important;
        padding-bottom: 3%;
        width: 100%;
        text-align: center;
    }

    .home3-21 {
        flex-direction: column;
        padding: 3% 4% !important;
        height: 100% !important;
    }

    .home3-2 {
        background: url("../images/home3-phone.png");
        background-size: cover;
        position: relative;
        z-index: 2;
        background-repeat: no-repeat;
        color: white;
        height: 100%;
        width: 100vw !important;
    }

    .black-bg {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        height: 100%;
    }

    .black-bg:before {
        position: absolute;
        content: '';
        background-color: black !important;
        width: 100vw;
        height: 100%;
        z-index: -1;
        opacity: 0.8;
    }

    .choose-content1 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .choose-content1 h1 {
        width: 90%;
        font-size: 1.5rem !important;
        font-weight: 700;
    }

    .strategy,
    .audience,
    .expertise {
        display: flex;
        margin-bottom: 4%;
        justify-content: center;
    }

    .outer-circle {
        width: 12vh;
        height: 12vh;
    }

    .strategy h3,
    .audience h3,
    .expertise h3 {
        font-size: 1.2rem;
        font-weight: 700;
    }

    .strategy p,
    .audience p,
    .expertise p {
        font-size: medium;
        font-weight: 400;
    }

    .audience {
        margin-left: 10%;
    }

    .home3-22 {
        padding: 5% 26%;
        flex-direction: column;
    }

    .work-recieved,
    .cup-of-coffee,
    .project-complete,
    .happy-clients {
        padding: 0 5%;
        margin: 8% 0;
        border-bottom: 2px solid rgba(255, 255, 255, 0.241);
    }

    .work-recieved,
    .cup-of-coffee,
    .project-complete {
        border-right: none;
    }

    .home3-23 {
        padding: 17% 10%;
    }

    .home3-23 p {
        font-size: 2rem;
    }

    .home3-23 h1 {
        font-size: 2.5rem;
        margin-bottom: 10%;
    }

    .home3-23 h4 {
        font-size: 1.2rem;
    }

    .home4-1 {
        text-align: center;
        padding-top: 10%;
        height: 45vh;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 0;
    }

    .home4-21 {
        width: 80%;
    }

    .gallery {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 60vh 60vh 60vh 60vh;
        column-gap: unset;
    }

    .vehicles {
        position: absolute;
        width: 80%;
        height: 60vh;
    }

    .valentine {
        grid-row: 2/3;
        grid-column: 1/2;
        height: 60vh;
        width: 80%;
        margin-left: 0;
        margin-top: 60vh;
    }

    .thug-life {
        grid-row: 3/4;
        grid-column: 1/2;
        height: 60vh;
        width: 80%;
        margin-left: 0;
        margin-top: 120vh;
    }

    .party {
        grid-row: 4/5;
        grid-column: 1/2;
        height: 60vh;
        width: 80%;
        margin-left: 0;
        margin-top: 180vh;
    }

    .fitness {
        visibility: hidden;
        grid-row: 5/6;
        grid-column: 1/2;
        height: 60vh;
        width: 80%;
        margin-left: 0;
        margin-top: 240vh;
    }

    .chaiPoint1 {
        visibility: hidden;
        grid-row: 6/7;
        grid-column: 1/2;
        height: 60vh;
        width: 80%;
        margin-left: 0;
        margin-top: 300vh;
    }
}

@media screen and (max-width:475px) {

    .start-project-btn,
    .about-us-btn,
    .contact-us {
        width: 40vw;
    }
}