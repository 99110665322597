.ser1 {
    position: fixed;
    z-index: -5;
    background-color: rgb(146, 55, 231);
    width: 30vw;
    top: -12vh;
    height: 150vh !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5%;
}

.ser1 h1 {
    font-weight: 700;
    font-size: 3.5vw;
}

.ser1 p {
    font-family: 'Kalam', cursive;
    font-size: 1.3rem;
}

.ser2 {
    display: grid;
    grid-template-columns: 30vw 70vw;
    margin-bottom: 20%;
}

.ser2-2 {
    display: grid;
    grid-template-columns: auto auto;
    padding: 4%;
    column-gap: 3%;
    row-gap: 3%;
    /* height: 100%; */
    /* grid-template-rows: 40vh 40vh 40vh 40vh; */
    /* background-color: rgb(219, 219, 219); */
}

.service1 h3>svg,
.service2 h3>svg,
.service3 h3>svg,
.service4 h3>svg,
.service5 h3>svg,
.service6 h3>svg,
.service7 h3>svg,
.service8 h3>svg,
.service9 h3>svg,
.service10 h3>svg,
.service11 h3>svg,
.service12 h3>svg {
    font-size: 3.5vw;
    margin-bottom: -2%;
    /* color: blue; */
}

.service1 h3,
.service2 h3,
.service3 h3,
.service4 h3,
.service5 h3,
.service6 h3,
.service7 h3,
.service8 h3,
.service9 h3,
.service10 h3,
.service11 h3,
.service12 h3 {
    /* padding: 2%; */
    font-weight: 700;
    margin-bottom: 5%;
    /* color: red; */
}

.service1,
.service3,
.service5,
.service7,
.service9,
.service11 {
    border-right: 2px solid black;
    padding: 2% 5%;
}

.service2,
.service4,
.service6,
.service8,
.service10,
.service12 {
    padding: 0 5%;
}

.service1,
.service2,
.service3,
.service4,
.service5,
.service6,
.service7,
.service8,
.service9,
.service10 {
    border-bottom: 2px solid black;
}

.service3,
.service4,
.service5,
.service6,
.service7,
.service8,
.service9,
.service10,
.service11,
.service12 {
    margin-top: -10%;
}


@media screen and (max-width:1407px) {
    .ser2 {
        margin-bottom: 30%;
    }
}

@media screen and (max-width:1159px) {
    .ser2 {
        height: 100% !important;
        padding-bottom: 15%;
    }
}

@media screen and (max-width:940px) {
    .ser2 {
        padding-bottom: 45%;
    }
}

@media screen and (max-width:730px) {
    .ser2 {
        height: 100% !important;
        padding-bottom: 17%;

    }

    .ser2-2 {
        row-gap: 1%;
    }


    .service1 h3,
    .service2 h3,
    .service3 h3,
    .service4 h3,
    .service5 h3,
    .service6 h3,
    .service7 h3,
    .service8 h3,
    .service9 h3,
    .service10 h3,
    .service11 h3,
    .service12 h3 {
        font-size: 1.3rem;
    }

    .service1 p,
    .service2 p,
    .service3 p,
    .service4 p,
    .service5 p,
    .service6 p,
    .service7 p,
    .service8 p,
    .service9 p,
    .service10 p,
    .service11 p,
    .service12 p {
        font-size: 0.8rem !important;
    }

    .service1 h3>svg,
    .service2 h3>svg,
    .service3 h3>svg,
    .service4 h3>svg,
    .service5 h3>svg,
    .service6 h3>svg,
    .service7 h3>svg,
    .service8 h3>svg,
    .service9 h3>svg,
    .service10 h3>svg,
    .service11 h3>svg,
    .service12 h3>svg {
        font-size: 4.5vw;
        margin-bottom: -2%;
        /* color: blue; */
    }

}

@media screen and (max-width:556px) {
    .ser1 {
        width: 40vw;
    }

    .ser1 h1 {
        font-weight: 700;
        font-size: 1.5rem;
    }

    .ser1 p {
        font-size: 1.1rem;
    }

    .ser2 {
        grid-template-columns: 40vw 60vw;
        margin-bottom: 50%;
    }

    .ser2-2 {
        grid-template-columns: auto;
        row-gap: 0.5%;
    }

    .service1,
    .service3,
    .service5,
    .service7,
    .service9,
    .service11 {
        border-right: unset;
        padding: 1% 5%;
    }

    .service2,
    .service4,
    .service6,
    .service8,
    .service10,
    .service12 {
        padding: 1% 5%;
    }

    .service1,
    .service2,
    .service3,
    .service4,
    .service5,
    .service6,
    .service7,
    .service8,
    .service9,
    .service10,
    .service11 {
        border-bottom: 2px solid black;
    }

    .service1 h3>svg,
    .service2 h3>svg,
    .service3 h3>svg,
    .service4 h3>svg,
    .service5 h3>svg,
    .service6 h3>svg,
    .service7 h3>svg,
    .service8 h3>svg,
    .service9 h3>svg,
    .service10 h3>svg,
    .service11 h3>svg,
    .service12 h3>svg {
        font-size: 6vw;
        margin-bottom: -2%;
        /* color: blue; */
    }
}

@media screen and (max-width:400px) {
    .ser2 {
        margin-bottom: 75%;
    }
}