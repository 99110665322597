header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20%;
  height: 12vh;
  padding: 0 2rem;
  background-color: rgba(177, 177, 180);
  color: black;
  position: relative;
  z-index: 1000;
  width: 100vw;
}

.logo {
  height: 4vw;
  width: 14vw;
}

nav a {
  margin: 0 1rem;
  color: unset;
  text-transform: capitalize;
  transition: 0.5s;
  font-weight: 700;
}

nav a:hover {
  transform-origin: left;
  color: blueviolet;
  transition: 0.5s;
}

header .nav-btn {
  padding: 10px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
  column-gap: 15%;
}

@media only screen and (max-width: 1024px) {
  .logo {
    height: 6vw;
    width: 21vw;
  }

  header {
    color: white;
  }

  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: black;
    transition: 1s;
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 550px) {
  .logo {
    height: 15vw;
    width: 50vw;
  }
}
