.contact1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ececec;
    width: 100vw;
    /* color: white; */
}

.contact1 h1 {
    font-size: 3.8vw;
    font-weight: 700;
    margin-top: 4%;
    border-bottom: 4px solid red;
    text-align: center;
    width: 20%;
}

.contact1 h4 {
    font-family: 'Kalam', cursive;
    margin-top: 1%;
    font-size: x-large;
}

.form {
    display: flex;
    flex-direction: column;
}

.contact-black h1 {
    color: #ffffff;
    /* font-family: 'Poppins', sans-serif; */
    font-weight: 700;
    font-size: 60px;
    margin-top: 10%;
}

.contact2 {
    background-color: rgb(237, 237, 237);
    display: flex;
    flex-wrap: wrap;
    padding: 5% 10%;
    width: 100vw;
    background-color: #ececec;
    color: black;
}

.send-message {
    display: flex;
    flex-direction: column;
    color: black;
    margin-bottom: 8%;
}

.send-message h1 {
    /* font-family: 'Lilita One', cursive; */
    font-size: 2vw;
    margin-bottom: 7%;
    width: 45vw;
    font-weight: 700;
}

.fname,
.email,
.phone,
.company,
.message {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid black;
    margin-bottom: 6%;
    background-color: transparent;
    height: 30px;
    font-size: medium;
    border-radius: unset;
}

.fname:active {
    background-color: transparent;
}

.button-30 {
    align-items: center;
    appearance: none;
    background-color: rgb(237, 237, 237);
    border-radius: 4px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    box-sizing: border-box;
    color: #36395A;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
    margin-top: 5%;
}

.button-30:focus {
    box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-30:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    transform: translateY(-2px);
}

.button-30:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
}

.get-in-touch {
    background-color: rgb(175, 104, 241);
    border-radius: 10px;
    padding: 4% 5%;
    margin-left: auto;
    color: black;
    width: 28vw;
    display: flex;
    flex-direction: column;
}

.get-in-touch h1 {
    /* font-family: 'Lilita One', cursive; */
    font-size: 2vw;
    font-weight: 700;
}

.line {
    border: 1px solid black;
    margin: 10% 0;
}

.visit-us,
.mail-us,
.phone-us {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: auto auto;
    column-gap: 5%;
    margin-top: 10%;
    align-items: center;

}

.icon-bg {
    grid-row: 1/3;
    background-color: rgb(156, 70, 237);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 20%;
}

.visit-us h3,
.mail-us h3,
.phone-us h3 {
    font-size: 1.4vw !important;
}

.visit-us p,
.mail-us p,
.phone-us p {
    font-size: 1.1vw;
}

.icon-bg svg {
    font-size: 2.7vw;
}

@media screen and (max-width: 1100px) {

    .contact2 {
        display: grid !important;
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        align-items: center;
        justify-content: center;
        /* margin-bottom: -10%; */
    }

    .icon-bg svg {
        font-size: 4vw;
    }
}

@media screen and (max-width:733px) {

    .contact1 h1 {
        font-size: 6vw;
        width: 40%;
    }

    .contact1 h4 {
        font-size: larger;
        width: 65%;
        text-align: center;
    }

    .section2-contact {
        margin-bottom: -30%;
    }

    .get-in-touch {
        width: 80vw;
    }

    .send-message h1 {
        font-size: 1.1rem;
        margin-bottom: 7%;
        width: 45vw;
        font-weight: 700;
    }

    .get-in-touch h1 {
        font-size: 1.1rem;
        font-weight: 700;
    }

    .line {
        margin: 3% 0;
    }

    .visit-us,
    .mail-us,
    .phone-us {
        display: grid;
        grid-template-columns: 30% 70%;
        grid-template-rows: auto auto;
        column-gap: 0;
        margin-top: 10%;
        align-items: center;
        margin-left: 10%;

    }

    .icon-bg {
        grid-row: 1/3;
        background-color: rgb(156, 70, 237);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 20%;
        width: 70%;
    }

    .visit-us h3,
    .mail-us h3,
    .phone-us h3 {
        font-size: 1.3rem !important;
    }

    .visit-us p,
    .mail-us p,
    .phone-us p {
        font-size: 0.9rem;
    }

    .icon-bg svg {
        font-size: 4.8vw;
    }
}

@media screen and (max-width:479px) {
    .contact-black h1 {
        margin-top: 22% !important;
        font-size: 40px;
    }

    .icon-bg svg {
        font-size: 5.5vw;
    }
}